import React, { useRef } from 'react'
import useIncrement from '../../../hooks/useIncrement'
import MediaQuery from 'react-responsive'
import { useViewportSpy } from 'beautiful-react-hooks'
import cn from 'classnames'
import NumberFormat from 'react-number-format'

import styles from './AutomotiveStats.module.scss'

const AutomotiveStats = () => {
    const ref = useRef();
    const shouldAwait = !useViewportSpy(ref);
    const average = useIncrement(657, 8, 25, 0, shouldAwait)
    const vehicles = useIncrement(108316, 1500, 25, 0, shouldAwait)
    const leads = useIncrement(566142, 6200, 25, 0, shouldAwait)
    const campaigns = useIncrement(5528, 55, 25, 0, shouldAwait)
    return (
        <section className={styles.automotiveStats}>
            <h2 className={styles.title}>Target, capture, <MediaQuery maxWidth={1024}><br /></MediaQuery> sell, <strong>repeat.</strong></h2>

            <div className={styles.statsContainer} ref={ref}>
                <div className={styles.loaderContaier}>
                    <div className={cn(styles.stat, styles.campaignsStat)}>
                        <span className={styles.value}>
                            <NumberFormat
                                thousandSeparator={true}
                                displayType={'text'}
                                value={campaigns}
                            />
                        </span>
                        <span className={styles.label}>
                            Campaigns Run
                        </span>
                    </div>
                    {/* <div className={styles.circleLoader} /> */}
                </div>

                <div className={styles.loaderContaier}>
                    <div className={cn(styles.stat, styles.leadsStat)}>
                        <span className={styles.value}>
                            <NumberFormat
                                thousandSeparator={true}
                                displayType={'text'}
                                value={leads}
                            />
                        </span>
                        <span className={styles.label}>Leads Generated</span>
                    </div>
                    {/* <div className={styles.circleLoader} /> */}
                </div>

                <div className={styles.loaderContaier}>
                    <div className={cn(styles.stat, styles.vehiclesStat)}>
                        <span className={styles.value}>
                            <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={vehicles}
                            />
                        </span>
                        <span className={styles.label}>Vehicles Sold</span>
                    </div>
                    {/* <div className={styles.circleLoader} /> */}
                </div>


                <div className={styles.loaderContaier}>
                    <div className={cn(styles.stat, styles.emphasized)}>
                        <span className={styles.value}>
                        <NumberFormat
                            thousandSeparator={true}
                            displayType={'text'}
                            value={average}
                        />%</span>
                        <span className={styles.label}>Average Event ROI</span>
                    </div>
                    {/* <div className={styles.circleLoader} /> */}
                </div>
            </div>

            <p className={styles.subtitle}>
                We care deeply about results. The core of our business philosophy is to continually increase customer value. It is why so many of our customers have trusted us time and time again with their hard-earned marketing dollars.
            </p>
        </section>
    );
};

export default AutomotiveStats
