import * as React from 'react'

function Volkswagen (props) {
  return (
    <svg
      width={60}
      height={59}
      viewBox="0 0 60 59"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"LOGO - VW"}</title>
      <path
        d="M29.764 59c16.367 0 29.594-13.184 29.594-29.5C59.358 13.185 46.13 0 29.764 0S.171 13.185.171 29.5C.17 45.816 13.397 59 29.764 59m-8.556-16.192c-.165 0-.248-.165-.33-.33L7.238 15.08c2.44-3.708 5.704-6.84 9.63-9.023l9.796 21.754c.166.412.413.494.745.494h4.71c.332 0 .58-.082.745-.494L42.66 6.057c3.926 2.184 7.192 5.315 9.63 9.023L38.652 42.479c-.083.164-.166.329-.331.329s-.248-.165-.331-.33l-5.125-11.7c-.165-.412-.413-.495-.744-.495h-4.711c-.332 0-.58.083-.745.494L21.54 42.48c-.084.164-.166.329-.332.329m8.556-19.364c-.165 0-.248-.165-.33-.33l-8.432-18.87a25.764 25.764 0 018.762-1.525c3.058 0 6.034.536 8.762 1.525l-8.43 18.87c-.084.165-.167.33-.332.33m0 32.837C15.05 56.281 2.982 44.167 2.982 29.5c0-3.296.578-6.427 1.694-9.353L20.34 51.584c.207.414.455.741.952.741.495 0 .768-.335.95-.74l7.191-16.11c.083-.165.166-.33.331-.33s.248.165.331.33l7.191 16.11c.182.405.455.74.95.74.497 0 .745-.328.952-.74l15.665-31.438c1.115 2.926 1.694 6.057 1.694 9.353 0 14.667-12.069 26.781-26.783 26.781"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Volkswagen
