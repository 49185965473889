import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import { useMediaQuery } from 'beautiful-react-hooks'

import cn from 'classnames'
import IconPrev from './IconPrev'
import IconNext from './IconNext'

import stars from './images/stars.png'
import honda from './images/honda.png'
import vw from './images/vw.png'
import kia from './images/kia.png'
import porsche from './images/porsche.png'
import ford from './images/ford.png'
import mazda from './images/mazda.png'
import toyota from './images/toyota.png'
import bmw from './images/bmw.png'
import cadillac from './images/cadillac.png'
import acura from './images/acura.png'

import styles from './AutomotiveTestimonial.module.scss'

const SLIDES = [
    {
        message: "Great event we were blown away at the customer response vs. other types of upgrade events run in the past. I strongly recommend the desk of the dealer program to dealers who feel that their clients are growing tired of the standard upgrade format.",
        stars: stars,
        brandInfo: "Volkswagen, Fredericton, NB",
        logo: vw,
        logoAlt: 'Volkswagen',
        logoWidth: 40,
        logoHeight: 40
    },
    {
        message: "Great job all around – our sales team loved the originality of the letter. We had a great show rate and were able to convert many sales! The Shift Marketing team was fantastic, especially Alysia – great follow up and was there if I had any questions or concerns! Look forward to running another Desk of the Dealer sale this month",
        stars: stars,
        brandInfo: "Honda, Brantford, ON",
        logo: honda,
        logoAlt: 'Honda',
        logoWidth: 64,
        logoHeight: 30,
    },
    {
        message: "It was Porsche’s first “sale” in many years and the feedback was very positive. We would want to do this once a year at the same time as what we did this year.",
        stars: stars,
        brandInfo: "Porsche, Woodbridge, ON",
        logo: porsche,
        logoAlt: 'Porsche',
        logoWidth: 83,
        logoHeight: 42,
    },
    {
        message: "This was our most successful Shift event to date. We wrote enough deals to effectively double our month-to-date and the buzz created has kept traffic up since. Highly recommended.",
        stars: stars,
        brandInfo: "Kia, Sarnia, ON",
        logo: kia,
        logoAlt: 'kia',
        logoWidth: 71,
        logoHeight: 36
    },
    {
        message: "Another successful sale! The staff at Shift Marketing were great to deal with. The amount of response to our sale was great overall.",
        stars: stars,
        brandInfo: "Mazda, Dieppe, NB",
        logo: mazda,
        logoAlt: 'Mazda',
        logoWidth: 50,
        logoHeight: 45,
    },
    {
        message: "Rosie is awesome, as is the team at Shift Marketing! She walked us through every step of the sale and was able to help with any issues we had at a moments notice even on a Saturday evening! The event itself worked very well, and the Shift Marketing site was easy to use and worked flawlessly. Valley Ford in Hague will be using their services again.",
        stars: stars,
        brandInfo: "Ford, Hague, SK",
        logo: ford,
        logoAlt: 'Ford',
        logoWidth: 74,
        logoHeight: 28,
    },
    {
        message: "Alysia was terrific in being able to turn this around for us with a limited time schedule. Well executed!",
        stars: stars,
        brandInfo: "Acura, London, ON",
        logo: acura,
        logoAlt: 'Acura',
        logoWidth: 42,
        logoHeight: 41,
    },
    {
        message: "Great outcome, and great service from Shift Marketing. Thank you Brittany and team.",
        stars: stars,
        brandInfo: "Toyota, Grand Falls-Windsor, NL",
        logo: toyota,
        logoAlt: 'Toyota',
        logoWidth: 71,
        logoHeight: 36,
    },
    {
        message: "We were very pleased with the results.",
        stars: stars,
        brandInfo: "Cadillac, Beachwood, Ohio",
        logo: cadillac,
        logoAlt: 'Cadillac',
        logoWidth: 71,
        logoHeight: 44
    },
    {
        message: "Great website and clean professional process!",
        stars: stars,
        brandInfo: "BMW, Ajax, ON",
        logo: bmw,
        logoAlt: 'bmw',
        logoWidth: 44,
        logoHeight: 44
    },
    {
        message: 'This was our most successful Shift event to date. We wrote enough deals to effectively double our month-to-date and the buzz created has kept traffic up since. Highly recommended.',
        stars: stars,
        brandInfo: 'Kia, Sarnia, ON',
        logo: kia,
        logoAlt: 'Kia',
        logoWidth: 68,
        logoHeight: 33,
    },
    {
        message: 'Great event we were blown away at the customer response vs. other types of upgrade events run in the past. I strongly recommend the desk of the dealer program to dealers who feel that their clients are growing tired of the standard upgrade format.',
        brandInfo: 'Volkswagen, Fredericton, NB',
        stars: stars,
        logo: vw,
        logoAlt: 'Volkswagen',
        logoWidth: 40,
        logoHeight: 40,
    },
    {
        message: "I could not have been more impressed by our Upgrade Sale Event with Shift Marketing. It was a simple seamless process, that delivered better sales results than I would have ever expected. A big thanks to the helpful, professional, and friendly team at Shift Marketing.",
        stars: stars,
        brandInfo: 'Hyundai, Grand Falls-Windsor, NL',
        logo: honda,
        logoAlt: 'Honda',
        logoWidth: 64,
        logoHeight: 30,
    },
    {
        message: 'It was Porsche’s first “sale” in many years and the feedback was very positive. We would want to do this once a year at the same time as what we did this year.',
        stars: stars,
        brandInfo: 'Porsche, Woodbridge, ON',
        logo: porsche,
        logoAlt: 'Porsche',
        logoWidth: 83,
        logoHeight: 42,
    }
]

const AutomotiveTestimonial = () => {
    const swiperRef = useRef(null)
    const [onReachEnd, setReachEnd] = useState(false)
    const [onBeginning, setBeginning] = useState(false)
    const isMobile = useMediaQuery('(max-width: 992px)')

    const goNext = () => {
        if (!onReachEnd && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slideNext()
        }
    }

    const goPrev = () => {
        if (!onBeginning && swiperRef.current && swiperRef.current.swiper) {
            swiperRef.current.swiper.slidePrev()
        }
    }

    return (
        <section className={styles.automotiveTestimonial}>
            <h2 className={styles.title}>Don't just take our word for it!</h2>
                <Swiper
                    spaceBetween={30}
                    // centeredSlidesBounds
                    centeredSlides
                    slidesPerView={1}
                    initialSlide={Math.ceil(SLIDES.length / 2)}
                    breakpoints={{
                        320: {
                            slidesPerView: 1.5,
                            spaceBetween: 22
                        },
                        768: {
                            slidesPerView: 2.5,
                            spaceBetween: 20,
                        },
                        1200: {
                            slidesPerView: 2.9,
                            spaceBetween: 30
                        },
                        1440: {
                            slidesPerView: 4,
                            spaceBetween: 30
                        }
                    }}
                    onProgress={progress => {
                        setBeginning(progress.isBeginning)
                        setReachEnd(progress.isEnd)
                    }}
                    ref={swiperRef}
                >
                       {SLIDES.map(({
                           message,
                           stars,
                           brandInfo,
                           logo,
                           logoAlt,
                           logoHeight,
                           logoWidth
                       }, i) => (
                            <SwiperSlide style={{ width: '400px' }}>
                                <div className={styles.testimonialContainer} key={i}>
                                    <div
                                        className={styles.stars}
                                        style={{
                                            backgroundImage: `url(${stars})`,
                                        }}
                                    />

                                  <div className={styles.testimonial}>
                                        <p className={styles.testimonialP}>
                                            {formatMessage(message, isMobile)}
                                        </p>

                                        <div className={styles.testimonialMeta}>
                                            <strong>
                                            {brandInfo}
                                            </strong>
                                            <img
                                                style={{
                                                    width: logoWidth,
                                                    height: logoHeight,
                                                }}
                                                src={logo}
                                                alt={logoAlt}
                                            />
                                        </div>
                                  </div>
                                </div>
                            </SwiperSlide>
                       ))}
                </Swiper>


            <span className={styles.swiperNavigationContainer}>
                <div className={cn(styles.btn, styles.btnLeft, {
                    [styles.disable]: onBeginning,
                })} onClick={goPrev}>
                    <IconPrev backgroundColor={'#EC1B2E'} />
                </div>

                <div
                    className={cn(styles.btn, styles.btnNext, {
                        [styles.disable]: onReachEnd,
                    })}
                    onClick={goNext}
                >
                    <IconNext backgroundColor={'#EC1B2E'} />
                </div>
            </span>
        </section>
    )
}

const formatMessage = (message, slice) => {
    if (slice) {
        return `“${message.slice(0, 200)}${message.length >= 200 ? '...' : ''}”`
    }

    return `“${message}”`
}

export default AutomotiveTestimonial
