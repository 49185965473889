import * as React from "react"

function Kia (props) {
  return (
    <svg
      width={65}
      height={33}
      viewBox="0 0 65 33"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"LOGO - Kia"}</title>
      <g fill="#000" fillRule="evenodd">
        <path d="M32.437 30.82c-15.798 0-28.636-6.403-28.636-14.29 0-7.869 12.838-14.283 28.636-14.283 15.792 0 28.647 6.414 28.647 14.283 0 7.887-12.855 14.29-28.647 14.29M55.35 4.965C49.226 1.882 41.098.186 32.437.186c-8.653 0-16.777 1.696-22.911 4.778C3.383 8.049 0 12.154 0 16.53c0 4.39 3.383 8.496 9.526 11.577 6.134 3.082 14.258 4.777 22.911 4.777 8.66 0 16.789-1.695 22.912-4.777 6.147-3.08 9.538-7.187 9.538-11.577 0-4.377-3.391-8.481-9.538-11.566" />
        <path d="M34.392 8.205h-5.1c-.05 0-.05.044-.05.092v.827c0 .033 0 .084.05.084h.523c.18 0 .327.146.327.324V24.48c0 .198.15.343.344.343h3.906a.342.342 0 00.347-.343V8.55a.358.358 0 00-.347-.346M48.513 8.435c-.079-.2-.295-.23-.464-.23h-6.117c-.04 0-.08.044-.08.092v.827c0 .04.04.084.08.084h.453c.196 0 .33.113.33.284a.582.582 0 01-.042.197l-4.91 14.71c-.038.102-.006.215.063.306.06.08.143.117.26.117h3.817c.181 0 .382-.145.45-.343l3.283-10.316s.017-.142.138-.142c.124 0 .16.142.16.142l3.302 10.316c.09.204.28.343.461.343h3.82c.147 0 .212-.066.262-.117a.4.4 0 00.052-.306L48.513 8.435zM21.38 16.195c-.047-.085-.073-.114-.073-.19 0-.066.044-.135.073-.212v-.01l4.759-7.074c.066-.095.066-.216 0-.332a.308.308 0 00-.266-.172h-4.267c-.204 0-.473.16-.617.38l-3.713 5.268c-.115.179-.233.19-.247.19-.111 0-.18-.076-.18-.183V8.551a.348.348 0 00-.342-.346h-4.992c-.05 0-.087.044-.087.092v.828a.09.09 0 00.087.083h.524c.182 0 .334.146.334.324V24.48c0 .197.146.343.34.343h3.794c.187 0 .342-.16.342-.343v-6.294c0-.135.057-.211.18-.211.132 0 .182.09.24.168l.007.022 4.46 6.279c.145.218.4.379.61.379h4.497c.109 0 .226-.077.277-.183.064-.105.058-.236-.037-.357-.014-.022-5.644-8.008-5.703-8.087" />
      </g>
    </svg>
  )
}

export default Kia
