import React from "react";

function IconNext ({
	backgroundColor = '#FFFFFF'
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="70"
      height="70"
      viewBox="0 0 70 70"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(-1267 -5421)">
          <g transform="translate(-44 4972)">
            <g transform="translate(160 449)">
              <g transform="translate(1151)">
                <g transform="matrix(-1 0 0 1 70 0)">
                  <rect
                    width="68"
                    height="68"
                    x="1"
                    y="1"
                    fill="#EC232F"
                    stroke="#EC232F"
                    strokeWidth="2"
                    rx="34"
                  ></rect>
                  <path
                    fill="#FFF"
                    d="M33.759 26.247l9.642 17.8A2 2 0 0141.642 47H22.358a2 2 0 01-1.759-2.953l9.642-17.8a2 2 0 013.518 0z"
                    transform="rotate(-90 32 35)"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default IconNext
