import * as React from "react"

function Gm (props) {
  return (
    <svg
      width={60}
      height={60}
      viewBox="0 0 60 60"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"LOGO - GM"}</title>
      <path
        d="M13.76 46.273h32.512v-5.707H13.759v5.707zm0-26.685c0-3.262 1.542-6.215 6.17-6.215 4.431 0 6.17 2.72 6.17 5.675v2.238h-3.727v-2.102c0-1.62-1.055-2.54-2.366-2.54-1.312 0-2.511 1.125-2.511 2.745v12.23c0 1.62 1.276 2.583 2.202 2.583 1.614 0 2.676-1.195 2.676-3.354v-3.393H20.19v-2.863h5.911v12.59h-3.639V35.14c-.816 2.124-1.955 2.478-3.775 2.478-1.618 0-4.927-.917-4.927-5.473V19.588zm16.206-5.745h5.574l2.552 16.813 2.676-16.813h5.498v23.339h-3.733v-17.94l-2.745 17.96H36.74L33.699 19.28v17.94h-3.733V13.843zM0 60h60V0H0v60z"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Gm
