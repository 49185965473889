import React, { useRef, useState } from 'react'
import { useViewportSpy, useWindowScroll, useMediaQuery } from 'beautiful-react-hooks'
import cn from 'classnames'

import maskCover from './images/maskCover.png'
import iMac from './images/iMac.png'

import styles from './AutomotiveCovered.module.scss'

const AutomotiveCovered = () => {
    const isMobile = useMediaQuery('(max-width: 992px)')
    const ref = useRef()
    const isVisible = useViewportSpy(ref, {})
    const [translateY, setBackgroundPosition] = useState(50)

    useWindowScroll(event => {
        if (isMobile) {
            percent = 0
            return setBackgroundPosition(percent)
        }

        if (!isVisible) {
            return false
        }

        const scrollTop = window.pageYOffset || (document.documentElement || document.body.parentNode || document.body).scrollTop || window.scrollY
        let percent = Math.ceil(55 - (scrollTop * 65) / 2350).toFixed(2)

        if (percent <= 0) {
            percent = 0
        }

        setBackgroundPosition(percent)
    })

    return (
        <section className={styles.automotiveCovered} style={{
            backgroundImage: `url(${maskCover})`
        }}>
            <h2 className={styles.title}>We’ve got you covered.</h2>

            <span className={styles.subtitle}>
                Creating an effective direct marketing campaign <strong>is difficult.</strong>
            </span>

            <p className={styles.automotiveInfo}>
                Our Shift One marketing platform and our cross-functional team
                of marketers, industry insiders, creatives, developers and
                campaign managers have done the worrying for you. We are
                specialized in executing stunning and effective direct marketing
                campaigns that engage your customers and convert them to
                potential buyers.
            </p>

            <div ref={ref} className={styles.imacContainer}>
                <span className={cn(styles.iMac)} style={{
                    backgroundImage: `url(${iMac})`,
                    backgroundPositionY: 'bottom',
                    transform: `rotate3d(1, 0, 0, -${translateY}deg) translateY(${translateY}%)`
                }} />
            </div>
        </section>
    )
}

export default AutomotiveCovered
