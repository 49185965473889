import * as React from "react"

function Toyota (props) {
  return (
    <svg
      width={117}
      height={19}
      viewBox="0 0 117 19"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"LOGO - Toyota"}</title>
      <g fill="#000" fillRule="evenodd">
        <path d="M82.843 14.082c2.212-.033 4.045-2.068 4.068-4.553-.023-2.534-1.856-4.57-4.068-4.555-2.305-.015-4.14 2.02-4.069 4.555-.071 2.485 1.764 4.52 4.07 4.553M75.48 9.53c-.013-3.987 3.266-7.2 7.363-7.209 4 .008 7.284 3.222 7.363 7.209-.079 3.94-3.362 7.155-7.363 7.21-4.097-.055-7.376-3.27-7.363-7.21M98.537 5.355V16.36h-3.098V5.355h-4.458V2.89h11.82v2.465zM109.388 11.046h1.936l-1.936-4.933-1.937 4.933h1.937zm-2.91 2.276l-1.158 3.038h-3.68l5.81-13.47h3.874l5.62 13.47h-3.68l-1.163-3.038h-5.622zM41.573 5.355V16.36h-3.1V5.355h-4.262V2.89h11.625v2.465zM53.971 14.082c2.237-.033 4.069-2.068 4.07-4.553-.001-2.534-1.833-4.57-4.07-4.555-2.285-.015-4.118 2.02-4.065 4.555-.053 2.485 1.78 4.52 4.065 4.553m-7.36-4.553c.006-3.987 3.286-7.2 7.36-7.209 4.023.008 7.305 3.222 7.364 7.209-.06 3.94-3.341 7.155-7.364 7.21-4.074-.055-7.354-3.27-7.36-7.21M68.311 16.36h1.549v-5.124l5.426-8.346H71.8l-3.489 5.69-3.295-5.69h-3.68l5.426 8.346v5.124zM5.023 5.348c.463 1.673 2.886 3.087 6.389 3.715l-.01.548c0 3.343.866 6.185 2.27 7.514-6.265-.523-10.964-3.703-10.964-7.457 0-1.558.803-3.049 2.315-4.32zM19.536 9.61l-.01-.548c3.503-.628 5.926-2.042 6.389-3.715 1.512 1.271 2.315 2.762 2.315 4.32 0 3.754-4.7 6.934-10.964 7.457 1.404-1.329 2.27-4.171 2.27-7.514zm-4.067-5.476c.792 0 1.485 1.085 1.818 2.833a25.874 25.874 0 01-3.637 0c.334-1.748 1.026-2.833 1.819-2.833zm-2.028 5.19a24.811 24.811 0 004.056 0v.006c0 2.922-.891 5.21-2.028 5.21-1.138 0-2.028-2.288-2.028-5.21v-.006zm-5.975-4.82c0-.096.017-.193.052-.29.39-1.076 2.96-1.97 6.282-2.2-1.01.898-1.79 2.592-2.158 4.716-2.081-.356-3.589-1.01-4.037-1.758a.902.902 0 01-.139-.468zm15.866.468c-.448.748-1.955 1.402-4.036 1.758-.368-2.124-1.149-3.818-2.159-4.715 3.322.23 5.892 1.123 6.282 2.2a.85.85 0 01.052.289.9.9 0 01-.139.468zM15.47.113C6.939.113 0 4.337 0 9.53c0 5.192 6.94 9.417 15.469 9.417 8.53 0 15.469-4.225 15.469-9.417 0-5.193-6.94-9.417-15.47-9.417z" />
      </g>
    </svg>
  )
}

export default Toyota
