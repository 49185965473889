import React from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Autoplay } from 'swiper'
import MediaQuery from 'react-responsive'

import styles from './AutomotiveHero.module.scss'

import { Store } from 'store'
import { setModalOpen } from 'layouts/DefaultLayout'

import cn from 'classnames'

import MASK from './images/mask.jpg'

const IMAGES = [
    require('./images/slice1.jpg'),
    require('./images/slice2.jpg'),
    require('./images/slice3.jpg'),
    require('./images/slice4.jpg'),
]

SwiperCore.use([Autoplay])

const AutomotiveHero = () => {
    const { dispatch } = React.useContext(Store)
    return (
        <>
            <section
                className={styles.hero}
                style={{
                    backgroundImage: `url(${MASK})`,
                }}
            >
                <div className={styles.body}>
                    <div className={styles.left}>
                        <Swiper
                            spaceBetween={0}
                            slidesPerView={1}
                            pagination={false}
                            autoplay={{
                                delay: 5000,
                            }}
                            className={styles.swiperContainer}
                        >
                            <SwiperSlide className={styles.swiperSlide}>
                                <div
                                    className={styles.slideImg}
                                    style={{
                                        backgroundImage: `url(${IMAGES[0]})`,
                                    }}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={styles.swiperSlide}>
                                <div
                                    className={styles.slideImg}
                                    style={{
                                        backgroundImage: `url(${IMAGES[1]})`,
                                    }}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={styles.swiperSlide}>
                                <div
                                    className={styles.slideImg}
                                    style={{
                                        backgroundImage: `url(${IMAGES[2]})`,
                                    }}
                                />
                            </SwiperSlide>
                            <SwiperSlide className={styles.swiperSlide}>
                                <div
                                    className={styles.slideImg}
                                    style={{
                                        backgroundImage: `url(${IMAGES[3]})`,
                                    }}
                                />
                            </SwiperSlide>
                        </Swiper>
                    </div>

                    <div className={styles.right}>
                        <div className={styles.rightInner}>
                            <h1 className={styles.title}>
                                    Database marketing for dealers and OEMs who care
                                    about the customer experience and want to sell
                                    more cars.
                            </h1>
                            <button
                                onClick={() =>
                                    setModalOpen(dispatch, 'automotive')
                                }
                                className={cn(
                                    'sh-button-primary __large',
                                    styles.btn
                                )}
                                style={{ marginLeft: 0 }}
                            >
                                Book a demo
                            </button>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default AutomotiveHero
