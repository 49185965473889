import React from 'react'

import { Store } from 'store'
import { setModalOpen } from 'layouts/DefaultLayout'

import shiftDemo from './images/ShiftDemo.png'
import ipad from './images/cover_ipad.png'

import styles from './styles.module.scss'

export default function ShiftDemo (props) {
    const {dispatch} = React.useContext(Store)
    return (
        <div className={styles.shiftDemo}>
            <div className={styles.container}>
                <div className={styles.leftSide}>
                    <div
                        className={styles.shiftDemoImage}
                        style={{
                            backgroundImage: `url(${shiftDemo})`,
                            width: 178,
                            height: 54,
                        }}
                    />

                    <h2 className={styles.shiftDemoTitle}>
                        Introducing <br /> Shift One.
                    </h2>

                    <p className={styles.shiftDemoP}>
                        Manage your campaigns and track performance with ease.
                    </p>

                    <a
                        onClick={(e) => {
                            e.preventDefault();
                            setModalOpen(dispatch, 'automotive')
                        }}
                        className="sh-button-primary __large"
                        href="#">
                        Book a demo today!
                    </a>
                </div>

                <div className={styles.rightSide}>
                    <div
                        className={styles.ipad}
                        style={{ backgroundImage: `url(${ipad})` }}
                    />
                </div>
            </div>

            <div className={styles.cover}/>
        </div>
    )
}
