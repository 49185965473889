import * as React from 'react'

function Audi (props) {
  return (
    <svg
      width={86}
      height={30}
      viewBox="0 0 86 30"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <title>{"LOGO - Audi"}</title>
      <path
        d="M70.84 26.56c-2.55 0-4.938-.851-6.85-2.245a15.014 15.014 0 003.246-9.317c0-3.524-1.211-6.757-3.247-9.317a11.657 11.657 0 016.85-2.246c6.395 0 11.57 5.172 11.57 11.563 0 6.39-5.175 11.562-11.57 11.562m-9.305-4.692a11.491 11.491 0 01-2.264-6.87c0-2.579.837-4.946 2.264-6.87a11.469 11.469 0 012.264 6.87c0 2.575-.84 4.947-2.264 6.87m-2.455 2.447a11.528 11.528 0 01-6.858 2.241c-2.566 0-4.923-.83-6.842-2.241a15.012 15.012 0 003.246-9.317c0-3.524-1.21-6.757-3.246-9.317A11.485 11.485 0 0152.22 3.44c2.566 0 4.938.83 6.858 2.242a14.902 14.902 0 00-3.247 9.317c0 3.51 1.226 6.761 3.247 9.317m-16.156-2.447a11.49 11.49 0 01-2.264-6.87c0-2.579.838-4.946 2.264-6.87a11.469 11.469 0 012.264 6.87c0 2.575-.84 4.947-2.264 6.87m-2.455 2.447a11.528 11.528 0 01-6.858 2.241c-2.566 0-4.923-.83-6.842-2.241a15.011 15.011 0 003.247-9.317c0-3.524-1.211-6.757-3.247-9.317A11.485 11.485 0 0133.61 3.44c2.567 0 4.938.83 6.858 2.242a14.903 14.903 0 00-3.246 9.317 15.01 15.01 0 003.246 9.317m-16.155-2.447a11.489 11.489 0 01-2.264-6.87c0-2.579.837-4.946 2.264-6.87a11.469 11.469 0 012.264 6.87c0 2.575-.84 4.947-2.264 6.87m-2.456 2.447a11.653 11.653 0 01-6.85 2.245c-6.394 0-11.57-5.172-11.57-11.562 0-6.391 5.176-11.563 11.57-11.563 2.549 0 4.936.852 6.85 2.246a14.906 14.906 0 00-3.246 9.317c0 3.51 1.225 6.761 3.246 9.317M70.84 0a14.945 14.945 0 00-9.305 3.23A14.94 14.94 0 0052.229 0a14.947 14.947 0 00-9.306 3.23A14.94 14.94 0 0033.618 0a14.947 14.947 0 00-9.305 3.23A14.945 14.945 0 0015.007 0C6.717 0 0 6.713 0 14.998c0 8.284 6.718 14.998 15.007 14.998 3.516 0 6.747-1.208 9.306-3.231a14.95 14.95 0 009.305 3.23c3.516 0 6.747-1.207 9.305-3.23a14.95 14.95 0 009.306 3.23c3.516 0 6.747-1.207 9.305-3.23a14.943 14.943 0 009.305 3.23c8.29 0 15.008-6.713 15.008-14.997C85.847 6.713 79.129 0 70.839 0"
        fill="#000"
        fillRule="evenodd"
      />
    </svg>
  )
}

export default Audi
