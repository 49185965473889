import React from "react"
import cn from 'classnames'
// import PropTypes from "prop-types"

import styles from './OurApproach.module.scss'

import IA from './images/IA.png'
import Communication from './images/Communication.png'
import LeadGen from './images/LeadGen.png'
import Tracking from './images/Tracking.png'

// import { Store } from 'store'
// import { setModalOpen } from "layouts/DefaultLayout";

const OurApproach = (props) => {
    // const {dispatch} = React.useContext(Store)

    return (
        <div className={styles.ourApproach}>
            <h2 className={styles.title}>
                Our approach to a successful campaign.
            </h2>

            <div className={styles.ourApproachGrid}>
                <div className={cn(styles.ourApproachBox, styles.ia)}>
                    <div
                        className={styles.ourApproachBoxImage}
                        style={{
                            backgroundImage: `url(${IA})`,
                            width: 54,
                            height: 60
                        }}
                    />
                    <div className={styles.ourApproachBoxBody}>
                        <strong className={styles.ourApproachBoxTitle}>AI-Powered Data</strong>
                        <p className={styles.ourApproachBoxP}>
                            Using Shift’s ai-powered, lead <br />
                            scoring algorithm, we isolate <br />
                            the customers in your database most <br />
                            likely to respond.
                        </p>
                    </div>
                </div>

                <div className={cn(styles.ourApproachBox, styles.communication)}>
                    <div
                        className={styles.ourApproachBoxImage}
                        style={{
                            backgroundImage: `url(${Communication})`,
                            width: 70,
                            height: 56
                        }}
                    />
                    <div className={styles.ourApproachBoxBody}>
                        <strong className={styles.ourApproachBoxTitle}>
                            Communication
                        </strong>
                        <p className={styles.ourApproachBoxP}>
                            Cross media communication to <br />
                            capture attention wherever <br />
                            customers are most likely to <br />
                            respond.
                        </p>
                    </div>
                </div>

                <div className={cn(styles.ourApproachBox, styles.leadGen)}>
                    <div
                        className={styles.ourApproachBoxImage}
                        style={{
                            backgroundImage: `url(${LeadGen})`,
                            width: 52,
                            height: 52
                        }}
                    />
                    <div className={styles.ourApproachBoxBody}>
                        <strong className={styles.ourApproachBoxTitle}>
                            Landing pages
                        </strong>
                        <p className={styles.ourApproachBoxP}>
                            Maximize the number of leads and <br />
                            vehicles sold with impeccably <br />
                            designed brand strengthening, lead <br />
                            generating, landing pages.
                        </p>
                    </div>
                </div>

                <div className={cn(styles.ourApproachBox, styles.analyticsAndTracking)}>
                    <div
                        className={styles.ourApproachBoxImage}
                        style={{
                            backgroundImage: `url(${Tracking})`,
                            width: 52,
                            height: 56
                        }}
                    />
                    <div className={styles.ourApproachBoxBody}>
                        <strong className={styles.ourApproachBoxTitle}>
                            Results and analytics
                        </strong>
                        <p className={styles.ourApproachBoxP}>
                            Simple, one-page, CRM and <br />
                            campaign analytics dashboard to <br />
                            track the success in realtime to <br />
                            maximize performance.
                        </p>
                    </div>
                </div>

            </div>

        </div>
    )
}

// OurApproach.propTypes = {}

export default OurApproach
