import React from 'react'

import Layout from 'layouts/DefaultLayout'
import SEO from 'components/SEO'

import thumbnail from 'images/shift-automotive-thumbnail.jpg'

import ShiftDemo from 'components/ShiftDemo'
import FooterCTA from 'components/FooterCTA'
import AutomotiveHero from '../page-components/Automotive/AutomotiveHero/AutomotiveHero'
import AutomotiveBrands from '../page-components/Automotive/AutomotiveBrands/AutomotiveBrands'
import AutomotiveStats from '../page-components/Automotive/AutomotiveStats/AutomotiveStats'
import AutomotivePortfolio from '../page-components/Automotive/AutomotivePortfolio/AutomotivePortfolio'
import AutomotiveCovered from '../page-components/Automotive/AutomotiveCovered/AutomotiveCovered'
import OurApproach from '../page-components/Automotive/OurApproach/OurApproach'
import AutomotiveTestimonial from '../page-components/Automotive/AutomotiveTestimonial/AutomotiveTestimonial'

import './automotive.module.scss'

export default () => {
    return (
        <Layout modalType="automotive" logoText="Marketing">
            <SEO
                title="Automotive | Shift Marketing"
                description="The most effective collection of customer database, private sale events and direct marketing campaigns in the industry."
                image={`https://shiftmarketing.io${thumbnail}`}
                canonical={'https://shiftmarketing.io/automotive'}
                ogUrl={'https://shiftmarketing.io/automotive'}
                siteName={'Automotive | Shift Marketing'}
            />
            <AutomotiveHero/>
            <AutomotiveBrands/>
            <AutomotiveStats/>
            <AutomotiveCovered/>
            <AutomotivePortfolio/>
            <OurApproach/>
            <ShiftDemo/>
            <AutomotiveTestimonial/>
            <FooterCTA modalType="automotive"/>
        </Layout>
    )
}
