import React from 'react'

import Audi from './Brands/Audi.js'
import Ford from './Brands/Ford.js'
import Chrysler from './Brands/Chrysler.js'
import Toyota from './Brands/Toyota.js'
import Gm from './Brands/Gm.js'
import Kia from './Brands/Kia.js'
import Honda from './Brands/Honda.js'
import Volkswagen from './Brands/volkswagen.js'

import styles from './AutomotiveBrands.module.scss'

const AutomotiveBrands = () => {
    return (
        <section className={styles.automotiveBrands}>
            <div className={styles.logosContainer}>
                <div className={styles.logo}>
                    <Audi />
                </div>
                <div className={styles.logo}>
                    <Ford />
                </div>
                <div className={styles.logo}>
                    <Volkswagen />
                </div>
                <div className={styles.logo}>
                    <Toyota />
                </div>
                <div className={styles.logo}>
                    <Gm />
                </div>
                <div className={styles.logo}>
                    <Kia />
                </div>
                <div className={styles.logo}>
                    <Honda />
                </div>
                <div className={styles.logo}>
                    <Chrysler />
                </div>
            </div>
        </section>
    );
};

export default AutomotiveBrands
