import { useEffect, useState } from 'react'

function useIncrement(
    maxValue = 100,
    inc = 10,
    timeout = 25,
    initialValue = 0,
    sleep
) {
    const [num, setNum] = useState(initialValue)
    useEffect(() => {
        let refInterval = null
        if (!sleep) {
            refInterval = window.setInterval(() => {
                setNum(state => {
                    if (state < maxValue) {
                        return state + (inc % maxValue) + 1
                    }
                    window.clearInterval(refInterval)
                    return maxValue
                })
            }, timeout)
        } else {
            setNum(0)
        }
        return () => {
            if (refInterval !== null) {
                window.clearInterval(refInterval)
            }
        }
    }, [sleep, maxValue, inc, timeout, initialValue])
    return num
}

export default useIncrement
