import React from 'react'
import MediaQuery from 'react-responsive'
import cn from 'classnames'
import { Store } from 'store'
import { setModalOpen } from "layouts/DefaultLayout";

import styles from './AutomotivePortfolio.module.scss'

const AutomotivePortfolio = () => {
    const {dispatch} = React.useContext(Store)

    return (
        <section>
            <div className={styles.automotivePortfolio}>
                <div className={styles.container}>
                    <div className={styles.inner}>
                        <div className={styles.logo} />

                        <h2 className={styles.title}>A unique portfolio <br /> of loyalty campaigns.</h2>
                        <a
                            onClick={() => {
                                setModalOpen(dispatch, 'product_sheet_automotive')
                                // setModalOpen(dispatch, 'automotive')
                            }}
                            className={cn("sh-button-primary __large", styles.btn)}
                            href="#"
                        >
                            Download product sheets
                        </a>
                    </div>s
                </div>
            </div>
            <div className={styles.footer}>
                <strong>Do not limit your database marketing to just one campaign. </strong>
                <p>Give your clients a reason to enter into the purchase journey with a portfolio of engaging campaigns to choose from.</p>
            </div>
        </section>
    );
};

export default AutomotivePortfolio
